<template>
  <div>
      <Projects />
  </div>
</template>

<script>
// @ is an alias to /src
import Projects from '@/components/Projects.vue'
export default {
  components: {
    Projects
  }
}
</script>

