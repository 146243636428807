<template>
  <div>
      <Blog />
  </div>
</template>

<script>
// @ is an alias to /src
import Blog from '@/components/Blog.vue'
export default {
  components: {
    Blog
  }
}
</script>

