<template>
  <section class="container mx-auto p-6 mb-24">

    <div class="flex flex-col sm:text-center w-full mb-6 sm:mb-12">
      <span class="text-xs text-primary tracking-widest font-medium mb-1">Coding</span>
      <h2 class="font-bold text-4xl mb-5">Meine Skills</h2>
      <p class="text-md">Für manche ist es wie Magie.</p>
    </div>
    <div class="flex flex-wrap">
      <div
        class="lg:w-1/4 sm:w-1/2 w-full px-8 py-6 border-l-2 border-slate-300 dark:border-slate-800 transition duration-300">
        <h3 class="text-lg sm:text-xl font-medium title-font mb-2">Programmiersprachen</h3>
        <ul>
          <li><i class="fa-solid fa-chevron-right text-primary mr-4"></i>Python</li>
          <li><i class="fa-solid fa-chevron-right text-primary mr-4"></i>JavaScript<i
              class="fa-solid fa-stars text-yellow-300 ml-3"></i></li>
          <li><i class="fa-solid fa-chevron-right text-primary mr-4"></i>PHP<i
              class="fa-solid fa-stars text-yellow-300 ml-3"></i></li>
          <li><i class="fa-solid fa-chevron-right text-primary mr-4"></i>HTML<i
              class="fa-solid fa-stars text-yellow-300 ml-3"></i></li>
          <li><i class="fa-solid fa-chevron-right text-primary mr-4"></i>CSS<i
              class="fa-solid fa-stars text-yellow-300 ml-3"></i></li>
        </ul>

      </div>
      <div
        class="lg:w-1/4 sm:w-1/2 w-full px-8 py-6 border-l-2 border-slate-300 dark:border-slate-800 transition duration-300">
        <h3 class="text-lg sm:text-xl font-medium title-font mb-2">Frontend Frameworks</h3>
        <ul>
          <li><i class="fa-solid fa-chevron-right text-primary mr-4"></i>Vue.js<i
              class="fa-solid fa-stars text-yellow-300 ml-3"></i></li>
          <li><i class="fa-solid fa-chevron-right text-primary mr-4"></i>React</li>
          <li><i class="fa-solid fa-chevron-right text-primary mr-4"></i>Tailwindcss<i
              class="fa-solid fa-stars text-yellow-300 ml-3"></i></li>
          <li><i class="fa-solid fa-chevron-right text-primary mr-4"></i>Bootstrap</li>
          <li><i class="fa-solid fa-chevron-right text-primary mr-4"></i>UIkit</li>
        </ul>
      </div>
      <div
        class="lg:w-1/4 sm:w-1/2 w-full px-8 py-6 border-l-2 border-slate-300 dark:border-slate-800 transition duration-300">
        <h3 class="text-lg sm:text-xl font-medium title-font mb-2">Backend Frameworks</h3>
        <ul>
          <li><i class="fa-solid fa-chevron-right text-primary mr-4"></i>Processwire</li>
          <li><i class="fa-solid fa-chevron-right text-primary mr-4"></i>WordPress</li>
          <li><i class="fa-solid fa-chevron-right text-primary mr-4"></i>Flask</li>
          <li><i class="fa-solid fa-chevron-right text-primary mr-4"></i>Laravel</li>
        </ul>
      </div>
      <div
        class="lg:w-1/4 sm:w-1/2 w-full px-8 py-6 border-l-2 border-slate-300 dark:border-slate-800 transition duration-300">
        <h3 class="text-lg sm:text-xl font-medium title-font mb-2">Software</h3>
        <ul>
          <li><i class="fa-solid fa-chevron-right text-primary mr-4"></i>Visual Studio Code<i
              class="fa-solid fa-stars text-yellow-300 ml-3"></i></li>
          <li><i class="fa-solid fa-chevron-right text-primary mr-4"></i>PyCharm | PHPStorm</li>
          <li><i class="fa-solid fa-chevron-right text-primary mr-4"></i>GitHub<i
              class="fa-solid fa-stars text-yellow-300 ml-3"></i></li>
          <li><i class="fa-solid fa-chevron-right text-primary mr-4"></i>XAMPP<i
              class="fa-solid fa-stars text-yellow-300 ml-3"></i></li>
          <li><i class="fa-solid fa-chevron-right text-primary mr-4"></i>Netlify</li>
        </ul>
      </div>
    </div>
    <div class="mt-6 text-center text-sm text-slate-600 dark:text-slate-400"><i
        class="fa-solid fa-stars text-yellow-300 ml-3"></i> = In diesem Projekt verwendet.</div>

  </section>
</template>



<script>
export default {

}
</script>

