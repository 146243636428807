<template>
  <div>
      <BlogPost />
  </div>
</template>

<script>
// @ is an alias to /src
import BlogPost from '@/components/BlogPost.vue'
export default {
  components: {
    BlogPost
  }
}
</script>

