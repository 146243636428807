<template>
  <section class="container mx-auto p-6 mb-6">
    <div class="flex items-center border-2 border-slate-300 dark:border-slate-800 rounded-md p-6 transition duration-300">
      <div class="flex items-center self-stretch justify-center">
        <i class="fa-solid fa-heart text-6xl mr-5 text-rose-500"></i>
      </div>
      <p class="font-mono">
        Danke an
        <a rel="noopener" target="_blank" class="text-primary hover:text-primary-hover transition duration-300"
          href="https://github.com/yannirabe">Yanni</a>, durch den ich meine Leidenschaft zum Programmieren entdeckt habe.<br>
        Danke an alle, die mich auf diesem Weg, begleiten.
      </p>
    </div>
  </section>
</template>